<template>
  <div  class="row" v-if="section">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-6">
          <AlignInput v-model="section.Align" label="Align" />
        </div>
        <div class="col-md-6">
          <NumberInput v-model="section.Padding" label="Padding" />
        </div>
        <div class="col-md-6">
          <NumberInput v-model="section.FontSize" label="Font Size" />
        </div>
          <div class="col-md-6">
          <ColorInput v-model="section.TextColor" label="Color" />
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import CheckboxInput from '../../EmbeddedForms/Components/CheckboxInput'
import ColorInput from '../../EmbeddedForms/Components/ColorInput'
import NumberInput from '../../EmbeddedForms/Components/NumberInput'
import AlignInput from '../../EmbeddedForms/Components/AlignInput'
import TextareaEmojiPicker from "../../../common/TextareaEmojiPicker";
export default {
  components: {
    ColorInput, NumberInput, AlignInput, TextareaEmojiPicker,
    CheckboxInput,
  },
  props: {
    section: {
      type: [Object],
      default: null,
    },

    type: {
      type: String,
      default: ''
    }
  },
}
</script>
  
<style lang="scss" scoped>
.form-group {
  position: relative;
}
</style>
  